import React, { useEffect, useState } from "react";
import "./blog.css";
import { Banner, Navbar } from "../../components";
import { Footer } from "../../containers";
import blogbanner from "../../assets/blog-banner.svg";
import CTA from "../../components/cta/CTA";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";


const Blog = () => {
  const [listBlog, setListBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])
  const fetchBlogList = async () => {
    try {
      const api = "https://web-backend.fanuun.com/blogList";
      const response = await axios.get(api);
      let data = response.data.data;
      console.log('response.data.data', response.data.data)
      // let newList = data?.reverse();
      const sortedData = data?.sort((a, b) => dayjs(b.blogCreatedAt) - dayjs(a.blogCreatedAt));
      // console.log('newList',sortedData)
      setListBlog(data);
      setLoading(false);
    } catch (error) {
      // console.log(error)
      setError("Error fetching blog list.");
      setLoading(false);
    }
  };




  useEffect(() => {
    fetchBlogList();
  }, []);

  // Note: Handeling navigation here...!
  const navigate = useNavigate();

  const goToViewAll = () => {
    navigate("/viewall");
  };

  return (
    <>
      <Helmet>
        <title>FANUUN | Blog</title>
      </Helmet>
      <div className="screening__main-container-blog">
        <Navbar />
        <div className="mainContainerBlog">
          <div className="banner-container">
            <Banner text="Our" textred="Blogs" img={blogbanner} />
          </div>
          <div className="subContainerBlog">
            {loading ? (
              <p></p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <>
                {/* <h1 className="headingRecent" style={{marginLeft : '20px'}}>All blogs</h1> */}
                <div className="silce3Div">
                  {listBlog?.slice(0, 3).map((data) => {
                    let updatedHtml = data.subSection;
                    const sectionArray = updatedHtml.split(/<p>\s*<\/p>/);
                    let slicePara = sectionArray[0].slice(0, 150)
                    console.log(data, 'sectionArray')
                    return (
                      <div className="blogSubSection" key={data.id}>
                        <img
                          className="imageBlog"
                          src={data.mainImage}
                          alt={`Blog ${data.id}`}
                        />
                        <h1 className="headingBlog">{data.mainHeading.slice(0, 80)}</h1>

                        <p className="paraBlog" 
                          dangerouslySetInnerHTML={slicePara.length > 0 ? { __html: `${slicePara} ....` } : null}
                         />

                        <Link to={`/blog/${data._id}`} state={data}>
                          <button className="blogButton">Read More</button>
                        </Link>
                      </div>
                    )
                  })}
                </div>
                <div className="silce3Div">
                  {listBlog?.slice(3, 6).map((data) => {
                    let updatedHtml = data.subSection;
                    const sectionArray = updatedHtml.split(/<p>\s*<\/p>/);
                    let slicePara = sectionArray[0].slice(0, 150)
                    console.log(data, 'sectionArray')
                    return (
                      <div className="blogSubSection" key={data.id}>
                        <img
                          className="imageBlog"
                          src={data.mainImage}
                          alt={`Blog ${data.id}`}
                        />
                        <h1 className="headingBlog">{data.mainHeading.slice(0, 80)}</h1>

                        <p className="paraBlog" 
                          dangerouslySetInnerHTML={slicePara.length > 0 ? { __html: `${slicePara} ....` } : null}
                         />
                        <Link to={`/blog/${data._id}`} state={data}>
                          <button className="blogButton">Read More</button>
                        </Link>
                      </div>
                    )
                  })}
                </div>
                <div className="silce3Div">
                  {listBlog?.slice(6, 9).map((data) => {
                    let updatedHtml = data.subSection;
                    const sectionArray = updatedHtml.split(/<p>\s*<\/p>/);
                    let slicePara = sectionArray[0].slice(0, 150)
                    console.log(data, 'sectionArray')
                    return (
                      <div className="blogSubSection" key={data.id}>
                        <img
                          className="imageBlog"
                          src={data.mainImage}
                          alt={`Blog ${data.id}`}
                        />
                        <h1 className="headingBlog">{data.mainHeading.slice(0, 80)}</h1>

                        <p className="paraBlog" 
                          dangerouslySetInnerHTML={slicePara.length > 0 ? { __html: `${slicePara} ....` } : null}
                         />
                        <Link to={`/blog/${data._id}`} state={data}>
                          <button className="blogButton">Read More</button>
                        </Link>
                      </div>
                    )
                  })}
                </div>
                <div className="silce3Div">
                  {listBlog?.slice(9, 12).map((data) => {
                    let updatedHtml = data.subSection;
                    const sectionArray = updatedHtml.split(/<p>\s*<\/p>/);
                    let slicePara = sectionArray[0].slice(0, 150)
                    console.log(data, 'sectionArray')
                    return (
                      <div className="blogSubSection" key={data.id}>
                        <img
                          className="imageBlog"
                          src={data.mainImage}
                          alt={`Blog ${data.id}`}
                        />
                        <h1 className="headingBlog">{data.mainHeading.slice(0, 80)}</h1>

                           <p className="paraBlog" 
                          dangerouslySetInnerHTML={slicePara.length > 0 ? { __html: `${slicePara} ....` } : null}
                         />
                        <Link to={`/blog/${data._id}`} state={data}>
                          <button className="blogButton">Read More</button>
                        </Link>
                      </div>
                    )
                  })}
                </div>
               
               
              </>
            )}
          </div>
        </div>
        <div className="view-btn-style">
          <button onClick={goToViewAll} className="view-button">
            View All
          </button>
        </div>
        <CTA htag="Contact" red="FANUUN" htag2="to schedule a consultation" />
        <Footer />
      </div>
    </>
  );
};

export default Blog;
