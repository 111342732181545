import React from "react";

import {
  Blog,
  Header,
  Provide,
  Footer,
  // WhyFanuun,
  Process,
  Opp,
  Slide,
} from "../../containers";

import { Navbar, Brand, CTA } from "../../components";
import { Helmet } from "react-helmet";

const Home = () => (
  <>
    <Helmet>
      <title>FANUUN | Premier Global Immigration & Visa Consultation Services</title>
    </Helmet>
    <div className="home-main">
      <Navbar />

      <Header />
      <Brand />
      <Process />
      <Blog />
      <Provide />
      {/* <WhyFanuun /> */}
      <Slide />
      <Opp />
      <CTA htag="Are You Interested in " red="Immigration?" />

      <Footer />
    </div>
  </>
);

export default Home;
