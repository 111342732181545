import React, { useRef } from 'react'
import { Footer } from '../../containers'
import { Navbar } from '../../components'
import image from "../../assets/globalLinkBanner.png";
import introBG from "../../assets/gIntroBg.png";
import img1 from "../../assets/gLink1.png";
import img2 from "../../assets/gLink2.png";
import img3 from "../../assets/gLink3.png";
import img4 from "../../assets/gLink4.png";
import img5 from "../../assets/gLink5.png";
import img6 from "../../assets/gLink6.png";
import img7 from "../../assets/gLink7.png";
import img8 from "../../assets/gLink8.png";
import arroww from "../../assets/arroww.png";
import Registration from "../../assets/Group 700_2023-09-08/Group 699@3x.png";
import Documents from "../../assets/Group 700_2023-09-08/Group 700@3x.png";
import resume from "../../assets/Group 700_2023-09-08/Group 701@3x.png";
import passport from "../../assets/Group 700_2023-09-08/Group 702@3x.png";
import VisaIssuance from "../../assets/Group 700_2023-09-08/Group 703@3x.png";
import './globalink.css';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";


const GlobalLink = () => {

    const sliderRef = useRef()
    console.log(sliderRef)
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // variableWidth: true,
        centerMode: true,
        arrows: true
    };


    return (
        <>
            <Helmet>
                <title>FANUUN | GlobaLink</title>
            </Helmet>
            <div className='mainContainerGlobaLink'>
                <Navbar />
                <div className="bannerContainer"
                    style={{
                        backgroundImage: `url(${image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                >
                    <h1 className="bannerHeading">GlobaLink Insights:</h1>
                    <h1 className="bannerHeading">Bridging Ambitions with Global Opportunities</h1>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} className='subContainerglobaLink'>
                    <div className='subDivGloba' style={{
                        backgroundImage: `url(${introBG})`,
                        backgroundSize: 'cover',
                        objectFit: 'contain',
                        // padding: 22,
                        // margin: 43
                    }}>
                        {/* Intro */}
                        <div className='introContainer'>
                            <h3 className='introHeading'>Introduction</h3>
                            <p className='introText'><span className='text1'>GlobaLink Insights </span>
                                <span className='text2'>isn't just a service,</span> it's your compass in the ever-evolving global business and professional landscape. Whether you're a professional aiming for a global career or a business targeting international expansion, we've got you covered.</p>
                        </div>

                        {/* For Professionals */}
                        <div className='pDiv'>
                            <div className='pDivLeft'>
                                <h2 className='pHeading'>For Professionals</h2>
                                <h3 className='pSubHeading'>Aligning Your Profile with Global Opportunities</h3>
                                <p className='pText'>We understand that every country has its unique professional ecosystem. At FANUUN, we tailor your profile to echo with the nation's opportunities, be it the dynamic markets of the Gulf or the thriving hubs of the West.</p>
                            </div>
                            <div className="pDivRight">
                                <img src={img1} alt="alt" className='pImg' />
                            </div>
                        </div>
                    </div>

                    <div className='pImgDiv'>
                        <div className='subDivImg'>
                            <img src={img2} alt="alt" className='img'></img>
                            <div className='imgTextDivv'>
                                <p className='imgText'>Profile Tailoring</p>
                               <img src={arroww} alt="alt" className='imgTextImg' />
                            </div>
                        </div>

                        <div className='subDivImg'>
                            <img src={img3} alt="alt" className='img'></img>
                            <div className='imgTextDivv'>
                                <p className='imgText'>Custom Career Consultations</p>
                               <img src={arroww} alt="alt" className='imgTextImg' />
                            </div>
                        </div>

                        <div className='subDivImg'>
                            <img src={img4} alt="alt" className='img'></img>
                            <div className='imgTextDivv'>
                                <p className='imgText'>Strategic Immigration Planning</p>
                               <img src={arroww} alt="alt" className='imgTextImg' />
                            </div>
                        </div>
                    </div>

                    {/* For Businesses */}
                    <div className='bDiv'>
                        <div className="bDivLeft">
                            <img src={img5} alt="alt" className='bImg' />
                        </div>
                        <div className='bDivRight'>
                            <h2 className='pHeading'>For Businesses</h2>
                            <h3 className='pSubHeading'>Strategic Business Insights Before You Leap:</h3>
                            <p className='bText'>Global expansion is a significant move. Our milestone-based approach offers an initial overview, followed by deep dives into market studies, sector-specific insights, and actionable investment advice. By the time you decide to take the plunge, you're armed with all the knowledge you need.</p>
                        </div>
                    </div>

                    <div className='bImgDiv'>
                        <div className='subDivImg'>
                            <img src={img6} alt="alt" className='img'></img>
                            <div className='imgTextDivv'>
                                <p className='imgText'>Market Analysis</p>
                               <img src={arroww} alt="alt" className='imgTextImg' />
                            </div>
                        </div>

                        <div className='subDivImg'>
                            <img src={img7} alt="alt" className='img'></img>
                            <div className='imgTextDivv'>
                                <p className='imgText'>Investment Advice</p>
                               <img src={arroww} alt="alt" className='imgTextImg' />
                            </div>
                        </div>

                        <div className='subDivImg'>
                            <img src={img8} alt="alt" className='img'></img>
                            <div className='imgTextDivv'>
                                <p className='imgText'>Company Formation Guidance</p>
                               <img src={arroww} alt="alt" className='imgTextImg' />
                            </div>
                        </div>
                    </div>


                    <div className="generalProcessDivgermany" style={{ backgroundColor: '#f9f9f9', borderRadius: '10px', paddingTop: '10px' }} id='process'>
                        <div className="headindDivGenerl">
                            <h1 className="jobHeadinggermany">Our Process</h1>
                        </div>
                        <div className="generalProcessSubDivgermany">
                            <div className="leftSectionGeneralgermany">
                                <h3 className="subHeadingGeneralGermany">
                                    1. Consultation
                                </h3>
                                <p className='paraGeneralGermany'>Grasping your aspirations and target locations.</p>
                            </div>
                            <div className="rightSectionGeneralgermany">
                                <img
                                    src={Registration}
                                    className="rightSectionImggermany1"
                                    alt="register"
                                />
                            </div>
                        </div>
                        <div className="generalProcessSubDivgermany">
                            <div className="leftSectionGeneralgermany">
                                <h3 className="subHeadingGeneralGermany">
                                    2. Preliminary Insights
                                </h3>
                                <p className='paraGeneralGermany'>An overview of the market, sector, or job landscape.</p>
                            </div>
                            <div className="rightSectionGeneralgermany">
                                <img
                                    src={Documents}
                                    className="rightSectionImggermany"
                                    alt="img"
                                />
                            </div>
                        </div>
                        <div className="generalProcessSubDivgermany">
                            <div className="leftSectionGeneralgermany">
                                <h3 className="subHeadingGeneralGermany">
                                    3. Deep Dive Analysis
                                </h3>
                                <p className='paraGeneralGermany'>Detailed insights into cultural nuances, sector-specific demands, and more.</p>
                            </div>
                            <div className="rightSectionGeneralgermany">
                                <img
                                    src={resume}
                                    className="rightSectionImggermany"
                                    alt="img"
                                />
                            </div>
                        </div>
                        <div className="generalProcessSubDivgermany">
                            <div className="leftSectionGeneralgermany">
                                <h3 className="subHeadingGeneralGermany">
                                    4. Profile Tailoring/Business Strategy Formation
                                </h3>
                                <p className='paraGeneralGermany'>Refining your professional profile or business strategy based on insights.</p>
                            </div>
                            <div className="rightSectionGeneralgermany">
                                <img
                                    src={passport}
                                    className="rightSectionImggermany"
                                    alt="img"
                                />
                            </div>
                        </div>
                        <div className="generalProcessSubDivgermany">
                            <div className="leftSectionGeneralgermany">
                                <h3 className="subHeadingGeneralGermany">
                                    5. Feedback & Finalization
                                </h3>
                                <p className='paraGeneralGermany'>Incorporating your feedback to deliver a polished, actionable plan.</p>
                            </div>
                            <div className="rightSectionGeneralgermany">
                                <img
                                    src={VisaIssuance}
                                    className="rightSectionImggermany"
                                    alt="img"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div>
                <div className="need_immigration_for_uk_main_div" style={{ marginTop: '5%' }}>
                    <div className="need_immigration_for_uk_inner_div1">
                        <p>
                            Schedule an <span className="future"> Appointment </span>
                        </p>

                        <p
                            style={{
                                fontSize: "18px",
                                fontWeight: 200,
                                fontFamily: "Archivo",
                                textAlign: "justify",
                                color: "#000",
                            }}
                        >
                            Schedule an appointment with Fanuun today for the best guidance
                            and consultancy services for Canada immigration.
                        </p>
                    </div>

                    <div className="need_immigration_for_uk_inner_div2" >
                        <Link to="/contact">
                            <button
                                style={{
                                    height: "55px",
                                    borderRadius: "20px",
                                    border: "none",
                                }}
                            >
                                Contact Us
                            </button>
                        </Link>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default GlobalLink