import React, { useEffect, useState, useMemo } from 'react'
import { Banner, CTA, Navbar } from '../../components'
import { Footer } from '../../containers'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Pagination from '../Pagination/pagination'
import blogbanner from "../../assets/blog-banner.svg";

import './view-all-blogs.css'

let PageSize = 15;

const ViewAllBlogs = () => {

    // Note: Handeling states here...!
    const [currentPage, setCurrentPage] = useState(1);
    const [listBlog, setListBlog] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Note: this hook will run to call all blog list api when screen mounts...!
    useEffect(() => {
        const fetchBlogList = async () => {
            try {
                const api = "https://web-backend.fanuun.com/blogList";
                const response = await axios.get(api);
                let data = response.data.data;
                let newList = data.reverse();
                setListBlog(newList);
                setLoading(false);
                // console.log(listBlog, 'listlist')
            } catch (error) {
                setError("Error fetching blog list.");
                setLoading(false);
            }
        };
        fetchBlogList();
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [])

    // Calculate the current page's data using useMemo
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return listBlog.slice(firstPageIndex, lastPageIndex);
    }, [listBlog, currentPage]);


    return (
        <>
            <div className='screening__main-container'>
                <Navbar />
                <div className="banner-container321">
                <div className="banner-containersubb">
                    <Banner text="Our" textred="Blogs" img={blogbanner} />
                </div>
                </div>
                <div className="mainContainerBlog">
                    <div className='subContainerBlogAll'>
                        {loading ? (
                            <p></p>
                        ) : error ? (
                            <p>{error}</p>
                        ) : (
                            <>
                                <div style={{display : 'flex', justifyContent : 'center',width : '100%'}}>
                                    {/* <h1 className="headingRecent" style={{ textAlign: 'center' }}>All blogs</h1> */}
                                </div>
                                <div className="silce3DivAllBlogs">
                                    {currentTableData.map((data) => (
                                        <div className="blogSubSectionAll" key={data.id}>
                                            <img
                                                className="imageBlog"
                                                src={data.mainImage}
                                                alt={`Blog ${data.id}`}
                                            />
                                            <h1 className="headingBlog">{data.mainHeading}</h1>

                                            {/* Use Link to navigate to the detail page and pass data as state */}
                                            <Link to={`/blog/${data._id}`} state={data}>
                                                <button className="blogButton">Read More</button>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={listBlog.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default ViewAllBlogs