import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Banner, Navbar } from "../../components";
import DOMPurify from "dompurify";
import './blogDetails.css';
import blogbanner from "../../assets/blog-banner.svg";
import { Helmet } from 'react-helmet'
import axios from "axios";
import ScrollTopArrow from "../../components/scroll-top-arrow/ScrollTopArrow";

const BlogDetail = () => {

    const location = useLocation();
    const data = location.state;
    console.log('data', data);

    const navigate = useNavigate()
    // const queryParams = new URLSearchParams(window.location);
    const queryParams = window.location.href;
    const idFromUrl = queryParams.substring(queryParams.lastIndexOf('/') + 1);
    console.log(idFromUrl, 'queryParams')
    // useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // }, [])

    const fetchBlogById = async () => {
        try {
            let blogId = idFromUrl
            const api = `https://web-backend.fanuun.com/fetch/blog/${blogId}`;
            const response = await axios.get(api);
            console.log(response.data.data, 'response')
            setCurrentBlog(response.data.data)
            // let data = response.data.data;
            // console.log(response);
            // let newList = data.reverse();
            // setListBlog(newList);
            // setLoading(false);
            // console.log(listBlog, 'listlist')
        } catch (error) {
            // setError("Error fetching blog list.");
            // setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogById()
    }, [idFromUrl])



    const [listBlog, setListBlog] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentBlog, setCurrentBlog] = useState(null);
    const [subSectionByCurrentBlogId, setSubSectionByCurrentBlogId] = useState(null);


    const fetchBlogList = async () => {
        try {
            const api = "https://web-backend.fanuun.com/blogList";
            const response = await axios.get(api);
            let data = response.data.data;
            console.log(response);
            let newList = data.reverse();
            setListBlog(newList);
            setLoading(false);
            // console.log(listBlog, 'listlist')
        } catch (error) {
            setError("Error fetching blog list.");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogList();
    }, [])

    console.log('List:', listBlog);

    if (currentBlog !== null) {
        console.log(currentBlog, 'currentBlog')
    }

    // if (!data) {
    //     return <p>No data available.</p>;
    // }
    // console.log('data', data);


    useEffect(() => {
        if (currentBlog !== null) {
            let updatedHtml = currentBlog.subSection;
            console.log('updatedHtml', updatedHtml);

            // Split the updatedHtml into sections based on empty <p></p> tags
            const sectionArray = updatedHtml.split(/<p>\s*<\/p>/);
            // console.log(sectionArray, 'sectionArray');

            const wrapImgWithTag = (content) => {
                const imgTagRegex = /<img[^>]*>/g;

                const wrappedContent = content.replace(imgTagRegex, (imgTag) => {
                    return `<div class="img-wrapper">${imgTag}</div>`;
                });

                return wrappedContent;
            };

            const wrappedSections = sectionArray.map((section, index) => {
                const wrappedSection = wrapImgWithTag(section);

                const imgDivContentMatch = wrappedSection.match(/<div class="img-wrapper">(.*?)<\/div>/);

                return (
                    (currentBlog !== null) ? (
                        <>
                            {imgDivContentMatch ? (
                                (() => {
                                    const imgDivContent = imgDivContentMatch[0];
                                    const textDivContent = wrappedSection.replace(imgDivContent, '');

                                    if (currentBlog.imgAlignment === 'allRight') {
                                        return (
                                            <div key={index} className='content-section1'>
                                                <div className="imgDiv" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(imgDivContent) }} />
                                                <div className="textDivRight" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textDivContent) }} />
                                            </div>
                                        );
                                    } else if (currentBlog.imgAlignment === 'allLeft') {
                                        return (
                                            <div key={index} className='content-section'>
                                                <div className="imgDiv" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(imgDivContent) }} />
                                                <div className="textDivLeft" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textDivContent) }} />
                                            </div>
                                        );
                                    } else if (currentBlog.imgAlignment === 'textOverImage') {
                                        return (
                                            <div key={index} className='content-section2'>
                                                <div className="imgDiv1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(imgDivContent) }} />
                                                <div className="textDiv1" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textDivContent) }} />
                                            </div>
                                        );
                                    } else if (currentBlog.imgAlignment === 'textBelowImage') {
                                        return (
                                            <div key={index} className='content-section3'>
                                                <div className="imgDiv2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(imgDivContent) }} />
                                                <div className="textDiv2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textDivContent) }} />
                                            </div>
                                        );
                                    } else if (currentBlog.imgAlignment === 'alternatives') {
                                        return (
                                            <>
                                                {(index % 2 === 0) ? (
                                                    <div key={index} className='content-section1'>
                                                        <div className="imgDiv" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(imgDivContent) }} />
                                                        <div className="textDiv" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textDivContent) }} />
                                                    </div>
                                                ) : (
                                                    <div key={index} className='content-section'>
                                                        <div className="imgDiv" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(imgDivContent) }} />
                                                        <div className="textDiv" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textDivContent) }} />
                                                    </div>
                                                )}
                                            </>
                                        );
                                    } else {
                                        return (
                                            <div key={index} className='content-section'>
                                                <div className="imgDiv" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(imgDivContent) }} />
                                                <div className="textDiv" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textDivContent) }} />
                                            </div>
                                        );
                                    }
                                })()
                            ) : (
                                <div key={index} className={`content-section11`}>
                                    <div className="textDiv5" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(wrappedSection) }} />
                                </div>
                            )}
                        </>
                    ) : (
                        <p>No data available.</p>
                    )
                );
            });

            setSubSectionByCurrentBlogId(wrappedSections);
        }
    }, [currentBlog])



    // console.log(wrappedSections, 'wrappedSections')

    const goToViewAll = () => {
        navigate("/viewall");
    };

    console.log('wrappedSections')

    return (
        <>
            {
                (subSectionByCurrentBlogId !== null)
                    ?
                    (
                        <Helmet>
                            <title>FANUUN | Blog | {currentBlog?.mainHeading}</title>
                        </Helmet>
                    )
                    :
                    (<p></p>)
            }

            <Navbar />
            {
                (subSectionByCurrentBlogId !== null)
                    ?
                    (
                        <>
                            <div className="blogDetailContainer">
                                <div className="blogDetailsSubContainer">
                                    <div className="mainDivblogDetails">
                                        <div className="subDivblogDetailsContent">
                                            <div className="mainDivv">
                                                <div className="mainImgDiv">
                                                    <img src={currentBlog.mainImage} className="mainImage" alt="Main" />
                                                </div>
                                                <div className="linksDiv"></div>
                                            </div>
                                            <h2 className="mainHeadingBlogDetails"> {currentBlog.mainHeading} </h2>
                                            {/* <p className="mainPara"> {data.mainContent} </p> */}
                                        </div>
                                        <div className="subSectionDivvBlogDetails">{subSectionByCurrentBlogId}</div>
                                    </div>
                                </div>
                                <h1 className="headingRecent">Recent blogs</h1>
                                <div className="bottomBlogList">
                                    {
                                        (listBlog)
                                            ?
                                            (
                                                listBlog?.slice(0, 3).map((data, index) => {
                                                    return (
                                                        <div className="blogSubSectionDetails" key={data.id}>
                                                            <img
                                                                className="imageBlog"
                                                                src={data.mainImage}
                                                                alt={`Blog ${data.id}`}
                                                            />
                                                            <h1 className="headingBlog">{data.mainHeading}</h1>
                                                            {/* <h1 className="subHeadingBlog">{data.mainContent}</h1> */}

                                                            {/* Use Link to navigate to the detail page and pass data as state */}
                                                            <Link to={`/blog/${data._id}`} state={data}>
                                                                <button className="blogButton">Read More</button>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            )
                                            :
                                            (null)
                                    }
                                </div>
                                <div className="view-btn-style">
                                    <button onClick={goToViewAll} className="view-button">
                                        View All
                                    </button>
                                </div>
                            </div>
                        </>
                    )
                    :
                    (<p className="loadingText"></p>)
            }
        </>

    );
};

export default BlogDetail;