import { Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import Career from "./pages/Career/Career";
import USvisa from "./pages/US/US";
import UKvisa from "./pages/UK/UK";
import Contact from "./pages/Contact/Contact";
import Canada from "./pages/Canada/Canada";
import Formation from "./pages/Formation/Formation";
import UAE from "./pages/UAE/uae";
import GERMANY from "./pages/Germany/germany";
import Blog from "./pages/Blog/Blog";

import UaeIncorporation from "./pages/UaeIncororation/uaeIncorporation";

import {
  Attest,
  Document,
  Screening,
  Sealed,
  Translation,
} from "./pages/Services-subpage";

import Test from "./pages/Test/Test";
import ScrollToTop from "./ScrollToTop";
import Privacy from "./pages/Privacy/Privacy";
import PORTUGAL from "./pages/PORTUGAL/portugal";
import BlogDetail from "./pages/BlogDetails/blogDetails";
import ViewAllBlogs from "./pages/View-all/view-all-blogs";
import ScrollTopArrow from './components/scroll-top-arrow/ScrollTopArrow'
import {Helmet} from 'react-helmet'
import GlobalLink from "./components/global-link/GlobaLink";

function App() {
  return (
    <>
 
    <ScrollTopArrow />
      {/* <ScrollToTop /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/immigration" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/uk" element={<UKvisa />} />
        <Route path="/us" element={<USvisa />} />
        <Route path="/uae" element={<UAE />} />
        <Route path="/globalink" element={<GlobalLink />} />
        <Route path="/uaeincop" element={<UaeIncorporation />} />
        <Route path="/canada" element={<Canada />} />
        <Route path="/germany" element={<GERMANY />} />
        <Route path="/portugal" element={<PORTUGAL />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:postId" element={<BlogDetail />} />
        <Route path="/ksa" element={<Formation />} />
        <Route path="/attestation" element={<Attest />} />
        <Route path="/document" element={<Document />} />
        <Route path="/sealed" element={<Sealed />} />
        <Route path="/screening" element={<Screening />} />
        <Route path="/translation" element={<Translation />} />
        <Route path='/viewall' element={<ViewAllBlogs />} />

        <Route path="/test" element={<Test />} />
      </Routes>
    </>
  );
}

export default App;
